'use client';

import * as React from 'react';

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

const CookieAuthorization = () => {
  const [displayCookieAuth, setDisplayCookieAuth] = React.useState(false);

  React.useEffect(() => {
    if (window.localStorage.getItem('cookie-auth') === null) {
      setDisplayCookieAuth(true);
    }
  }, []);

  const _close = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    window.localStorage.setItem('cookie-auth', '1');
    setDisplayCookieAuth(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      message={'We use cookies to ensure you get the best experience on our website.'}
      onClose={_close}
      open={displayCookieAuth}
      action={
        <Button color="secondary" size="small" onClick={_close}>
          GOT IT!
        </Button>
      }
    />
  );
};

export default CookieAuthorization;
