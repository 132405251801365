'use client';

import type { AlertColor } from '@mui/material/Alert';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { create } from 'zustand';

export interface AlertInterface {
  message: string;
  open: boolean;
  severity: AlertColor;
}

const useAlert = create<AlertInterface>((set) => ({
  open: false,
  message: '',
  severity: 'success' as AlertColor,
}));

export const showAlert = (message: string, severity: AlertColor = 'success') => {
  useAlert.setState({ message, open: true, severity });
};

export const hideAlert = () => {
  useAlert.setState({ message: '', open: false, severity: 'success' as AlertColor });
};

export const Toaster = () => {
  const store = useAlert();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      autoHideDuration={6000}
      onClose={hideAlert}
      open={store.open}>
      <Alert onClose={hideAlert} severity={store.severity} sx={{ width: '100%' }}>
        {store.message}
      </Alert>
    </Snackbar>
  );
};

export default Toaster;
