export interface Color {
  10: string;
  20: string;
  30: string;
  40: string;
  50: string;
  60: string;
  70: string;
  80: string;
  90: string;
  100: string;
  110: string;
  120: string;
}
export const brownLight = {
  10: '#FEFDFC',
  20: '#FCF9F6',
  30: '#F6EEE7',
  40: '#F0E4D9',
  50: '#EBDACA',
  60: '#E4CDB7',
  70: '#DCBC9F',
  80: '#CEA37E',
  90: '#AD7F58',
  100: '#A07553',
  110: '#815E46',
  120: '#3E332E',
};

export const brownDark = {
  10: '#12110F',
  20: '#1C1816',
  30: '#28211D',
  40: '#322922',
  50: '#3E3128',
  60: '#4D3C2F',
  70: '#614A39',
  80: '#7C5F46',
  90: '#AD7F58',
  100: '#B88C67',
  110: '#DBB594',
  120: '#F2E1CA',
};

export const grayLight = {
  10: '#FCFCFD',
  20: '#F9F9FB',
  30: '#EFF0F3',
  40: '#E7E8EC',
  50: '#E0E1E6',
  60: '#D8D9E0',
  70: '#CDCED7',
  80: '#B9BBC6',
  90: '#8B8D98',
  100: '#80828D',
  110: '#62636C',
  120: '#1E1F24',
};

export const grayDark = {
  10: '#111113',
  20: '#19191B',
  30: '#222325',
  40: '#292A2E',
  50: '#303136',
  60: '#393A40',
  70: '#46484F',
  80: '#5F606A',
  90: '#6C6E79',
  100: '#797B86',
  110: '#B2B3BD',
  120: '#EEEEF0',
};

export const redLight = {
  10: '#FFFCFC',
  20: '#FFF7F7',
  30: '#FEEBEC',
  40: '#FFDBDC',
  50: '#FFCDCE',
  60: '#FDBDBE',
  70: '#F4A9AA',
  80: '#EB8E90',
  90: '#E5484D',
  100: '#DC3E42',
  110: '#CE2C31',
  120: '#641723',
};

export const redDark = {
  10: '#191111',
  20: '#201314',
  30: '#3B1219',
  40: '#500F1C',
  50: '#5E1A2E',
  60: '#72232D',
  70: '#8C333A',
  80: '#B54548',
  90: '#E5484D',
  100: '#EC5D5E',
  110: '#FF9592',
  120: '#FFD1D9',
};
